<template>
  <client-only>
    <el-dropdown>
      <span class="user-info">
        <el-avatar :size="40" :src="userInfo?.userHead" alt="头像" />
        <span class="user-name">{{ userInfo?.nickname }}</span>
      </span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item>
            <nuxt-link :to="{ name: 'account-course' }"> 我的课程 </nuxt-link>
          </el-dropdown-item>
          <el-dropdown-item>
            <nuxt-link :to="{ name: 'account-exam' }"> 我的考试 </nuxt-link>
          </el-dropdown-item>
          <el-dropdown-item>
            <nuxt-link :to="{ name: 'account-order' }"> 我的订单 </nuxt-link>
          </el-dropdown-item>
          <el-dropdown-item>
            <nuxt-link :to="{ name: 'account-user' }"> 个人信息 </nuxt-link>
          </el-dropdown-item>
          <!-- <el-dropdown-item @click="handleLogout"> 安全退出 </el-dropdown-item> -->
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </client-only>
</template>
<script setup>
  import { userApi } from '~/api/user'
  import { logout } from '~/utils/login'
  import { setStorage } from '~/utils/storage.js'

  const userInfo = ref({})
  onMounted(async () => {
    userInfo.value = await userApi.getUserInfo()
    setStorage('userInfo', userInfo.value)
  })

  // 退出登录
  function handleLogout() {
    ElMessageBox.confirm('确认退出', '退出登录', {
      confirmButtonText: '确认',
      showCancelButton: false,
      type: 'warning'
    }).then(() => {
      logout()
      location.reload()
    })
  }
</script>
<style lang="scss" scoped>
  .user-info {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .user-name {
    margin-left: 5px;
    max-width: 60px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .el-tooltip__trigger:focus {
    outline: none;
  }
</style>
